import React, { useContext, useEffect, useMemo, useState } from 'react';
import { SpecialCampaignBanner, AvailableIcons } from '@sbt-web/banners';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { Usefullinks } from '@sbt-web/ancillary-services';
import { getPublicUser } from '@sbt-web/auth';
import { CertyWidget } from '@sbt-web/certy';
import { useViewport } from '@sbt-web/hooks';
import {
  AdTypes,
  CategoryId,
  FeatureURI,
  HebeCampaignPosition,
  type AdItem,
  type ItemGeo,
  type PromoInfo,
} from '@sbt-web/networking';
import {
  BodyText,
  Caption,
  Headline5,
  ItemInsertionDate,
  ItemPrice,
  Notice,
} from '@sbt-web/ui';

import { type ShippingCosts } from 'server/detail-requests/ShippingCost';
import formatShippingInfo from '@client/utilities/format-shipping-info';
import { prepareRemoteDataAdDetail } from '@shared/helpers/Hebe';
import { getOrCreatePulse } from '@tools/tracking/utils';
import parsePrice from '@client/utilities/parse-price';
import { evalBoolFeature } from '@shared/models';

import {
  getTransactionFlows,
  TransactionFlow,
} from '@shared/tools/transaction/utils';
import { PayPalMessage } from '../PayPalMessage';
import { InfoBox } from '../InfoBox';
import { BuyNowButton } from '../AdBuy/components/AdBuyButtons/BuyNowButton';
import { SendOfferButton } from '../AdBuy/components/AdBuyButtons/SendOfferButton';

import ownClasses from './index.module.scss';
import { AdBuyEvent } from '../AdBuy';

export type AdInfoProps = {
  item: AdItem;
  FavoriteCounter: React.ReactNode | null;
  shippingCosts: ShippingCosts | null;
  promo: PromoInfo | null;
};

const parseId = (adUrn: string): string => adUrn.split(':')[4];

const PAYPAL_3_in_1_MIN_PRICE = 30;

const Location = ({ geoData }: { geoData: ItemGeo }) => {
  const { city, town } = geoData;
  const townString = town?.value ?? '';
  const cityString = city?.shortName ? `(${city.shortName})` : '';

  const locationString =
    townString || cityString ? `${townString} ${cityString}`.trim() : null;

  if (!locationString) {
    return null;
  }

  return (
    <div className={ownClasses.location}>
      <img
        src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/cactus/pin.svg`}
        alt=""
        height="16"
        width="16"
        decoding="async"
      />
      <BodyText size="small" classes={[ownClasses.locationText]}>
        {locationString}
      </BodyText>
    </div>
  );
};

const statusMessageMap = new Map<string, string>([
  [
    'IN_PROGRESS',
    "Il venditore ha già accettato un'offerta per questo articolo",
  ],
  ['SOLD', 'Il venditore ha concluso la trattativa con TuttoSubito'],
]);

const regionsCertyWidget = ['1', '5', '19', '18', '16', '20', '4'];

const shouldRenderCertyWidget = (item: AdItem): boolean =>
  regionsCertyWidget.includes(item.geo.region.id) &&
  item.category.id === CategoryId.CaseVacanza;

const AdInfo = ({
  item,
  FavoriteCounter,
  shippingCosts,
  promo,
}: AdInfoProps) => {
  const parsedPrice = parsePrice(item.features[FeatureURI.Price]);

  const { isMobile, isDesktop } = useViewport();

  const isShippable = evalBoolFeature(item.features[FeatureURI.ItemShippable]);

  const isSubitoShipping =
    item.features[FeatureURI.ItemShippingType]?.values?.[0]?.key === '0';

  const transactionStatus: string | undefined =
    item.features[FeatureURI.TransactionStatus]?.values[0].value;
  const isSold = transactionStatus != undefined;

  // This is an overly-complex workaround because `getPublicUser` can't be
  // called on the server
  const [userId, setUserId] = useState<string | null>(null);
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [showPaypal, setShowPaypal] = useState(false);

  const transactionFlows = getTransactionFlows(
    item.features,
    item.advertiser,
    userId
  );

  const isBuyNowVisibleMemo = useMemo(
    () => transactionFlows.includes(TransactionFlow.BuyNow),
    [transactionFlows]
  );
  const isSendOfferVisibleMemo = useMemo(
    () =>
      !isBuyNowVisibleMemo &&
      transactionFlows.includes(TransactionFlow.SendOffer),
    [isBuyNowVisibleMemo, transactionFlows]
  );
  const isAdEligibleFor3in1 =
    showPaypal && parsedPrice && parsedPrice >= PAYPAL_3_in_1_MIN_PRICE;

  useEffect(() => {
    const id = getPublicUser()?.id;

    if (!id) return setShowPaypal(isSubitoShipping);
    setUserId(id);

    if (!isSubitoShipping) return;

    optimizely?.onReady().then(() => {
      const paypalBucket = optimizely.activate(
        'subito-paypal-removal-longrun-no-audience',
        id,
        { adURN: item.urn }
      );
      if (paypalBucket !== 'B') setShowPaypal(true);
    });
  }, [item.urn, optimizely, userId, isSubitoShipping]);

  const openBuyNowFlow = () => {
    window.dispatchEvent(new CustomEvent(AdBuyEvent.OpenCartDialog));
  };

  const openSendOfferFlow = () => {
    window.dispatchEvent(new CustomEvent(AdBuyEvent.OpenOfferDialog));
  };

  const userIsSeller = userId === item.advertiser.userId;

  return (
    <>
      <div className={ownClasses['ad-info__top-caption-wrapper']}>
        <div className={ownClasses['ad-info__listing-info']}>
          <ItemInsertionDate
            date={item.date}
            classes={[ownClasses['ad-info__listing-time']]}
          />
          {FavoriteCounter}
        </div>
        <Caption
          element="span"
          weight="book"
          classes={[ownClasses['ad-info__id']]}
        >
          {`ID: ${parseId(item.urn)}`}
        </Caption>
      </div>

      <Headline5 element="h1" classes={[ownClasses.title]}>
        {item.subject}
      </Headline5>

      <Location geoData={item.geo} />

      {parsedPrice && (
        <>
          <ItemPrice
            adType={item.type.key as AdTypes}
            price={parsedPrice ?? 0}
            classes={[ownClasses.price]}
            size="large"
            badgeConfiguration={formatShippingInfo(
              {
                [FeatureURI.ItemShippable]:
                  item.features[FeatureURI.ItemShippable],
                [FeatureURI.ItemShippingCostTuttoSubito]:
                  item.features[FeatureURI.ItemShippingCostTuttoSubito],
                [FeatureURI.ItemShippingCost]:
                  item.features[FeatureURI.ItemShippingCost],
                [FeatureURI.TransactionStatus]:
                  item.features[FeatureURI.TransactionStatus],
              },
              'detail'
            )}
          />
          {isAdEligibleFor3in1 && (
            <section className={ownClasses['paypal-section']}>
              <PayPalMessage amount={parsedPrice} />
            </section>
          )}
        </>
      )}
      {!isSold && !isDesktop && promo ? (
        <div className={ownClasses['campaign-banner-container']}>
          <SpecialCampaignBanner
            title={promo.content.title}
            description={
              // This check is duplicated to provide type refinement
              promo.content.countdown?.alternativeSubtitle !== undefined &&
              promo.content.countdown.alternativeSubtitle !== ''
                ? promo.content.countdown.alternativeSubtitle
                : promo.content.subtitle
            }
            iconName={promo.content.assets.resourceName as AvailableIcons}
            countdownTo={
              // This check is duplicated to provide type refinement
              promo.content.countdown?.alternativeSubtitle !== undefined &&
              promo.content.countdown.alternativeSubtitle !== ''
                ? promo.endDate
                : undefined
            }
            alternativeExternalIconURL={promo.content.assets.resourceUrl}
          />
        </div>
      ) : null}
      {isBuyNowVisibleMemo && (
        <div className={`sticky-cta-top-anchor ${ownClasses.buyButton}`}>
          <BuyNowButton onClick={openBuyNowFlow} />
        </div>
      )}
      {isSendOfferVisibleMemo && (
        <div className={`sticky-cta-top-anchor ${ownClasses.buyButton}`}>
          <SendOfferButton onClick={openSendOfferFlow} />
        </div>
      )}
      {isShippable && !isSold && shippingCosts && (
        <InfoBox shippingCosts={shippingCosts} showPayPal={showPaypal} />
      )}
      {shouldRenderCertyWidget(item) ? <CertyWidget /> : null}
      {isSold && !userIsSeller ? (
        <Notice
          classes={[ownClasses['sold-box']]}
          size="medium"
          variant="error"
          icon="TriangleExclamation"
        >
          {statusMessageMap.get(transactionStatus) ??
            "L'oggetto è stato già venduto"}
        </Notice>
      ) : null}
      <div className={ownClasses['useful-links-wrapper']}>
        <Usefullinks
          item={item}
          pulseInstance={getOrCreatePulse()}
          remoteData={prepareRemoteDataAdDetail(
            HebeCampaignPosition.BELOW_PRICE,
            item,
            isMobile
          )}
        />
      </div>
    </>
  );
};

AdInfo.displayName = 'AdInfo';

export default AdInfo;
