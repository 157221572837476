import React from 'react';
import classnames from 'classnames';

import classes from './shippingIcons.module.scss';

export const HomeShippingIcon = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    className={classnames(classes.icon, classes['shipping-destination-icon'])}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m12.503 3.948.067.064 2.439 2.642.002-1.03c0-.674.614-1.08 1.24-1.12l.105-.004 1.102.013c.616 0 1.242.378 1.307 1.014l.005.108v5.011l-.004.077 2.805 3.039a.75.75 0 0 1-1.034 1.082l-.068-.065-.2-.215.001.535a.75.75 0 0 1 0 1.302v.984a.749.749 0 0 1 0 1.23v1.636a.75.75 0 0 1-.663.745l-.087.005h-5.264a.75.75 0 0 1-.745-.663l-.005-.088v-2.162A.763.763 0 0 1 13.5 18l.005-.087v-.98c0-.59-.671-1.182-1.494-1.175-.76.007-1.27.437-1.491 1.206l-.017.065v3.221a.75.75 0 0 1-.662.745L9.754 21H4.52a.75.75 0 0 1-.745-.662l-.005-.088v-1.61a.75.75 0 0 1 0-1.28v-.97a.75.75 0 0 1 0-1.28v-.541l-.192.209a.75.75 0 0 1-.987.102l-.073-.06a.75.75 0 0 1-.102-.986l.06-.073 8.993-9.749a.75.75 0 0 1 1.035-.064ZM18.77 18.75h-3.764v.75h3.764v-.75Zm-9.766 0H5.269v.75h3.735l-.001-.75Zm9.766-2.25h-3.804c.018.097.03.196.037.296l.004.137v.317h3.763v-.75Zm-9.675 0H5.27v.75h3.735v-.317c0-.029.002-.057.005-.086l.015-.084c.02-.09.044-.178.07-.263ZM12.02 5.626l-6.75 7.317v2.056h4.745c.513-.464 1.188-.734 1.985-.74a3.154 3.154 0 0 1 2.063.74h4.707v-2.06l-6.75-7.313ZM12 8.25l.132.004A2.25 2.25 0 1 1 12 8.25Zm0 1.5-.088.005a.75.75 0 1 0 .088-.005Zm4.51-3.748-.004 2.067a.752.752 0 0 1-.023.182l.786.851V6.011l-.76-.009Z" />
  </svg>
);

export const DeliveryPointShippingIcon = () => (
  <svg
    viewBox="0 0 24 24"
    width="24"
    height="24"
    className={classnames(classes.icon, classes['shipping-destination-icon'])}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 22c5.333-5.055 8-9.055 8-12a8 8 0 1 0-16 0c0 2.945 2.667 6.945 8 12ZM6 10a6 6 0 1 1 12 0c0 2.008-1.969 5.156-6 9.207-4.031-4.051-6-7.2-6-9.207Zm6 3a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm0-2a1 1 0 1 1 0-2 1 1 0 0 1 0 2Z" />
  </svg>
);

export const PaymentIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    className={classnames(classes.icon, classes['shield-icon'])}
  >
    <path d="M2.055 4V3a1 1 0 00-.993.886L2.055 4zM8.22 2.747a1 1 0 10-1.33-1.494l1.33 1.494zm-.992 13.198a1 1 0 00.654-1.89l-.654 1.89zM2.055 4l.001 1h.019a3.725 3.725 0 00.166-.005c.109-.004.261-.013.449-.03.375-.035.896-.103 1.494-.24 1.184-.27 2.738-.824 4.036-1.978L6.89 1.253c-.952.846-2.148 1.293-3.152 1.522a9.494 9.494 0 01-1.581.222A3.935 3.935 0 012.055 3h-.002.001l.001 1zm0 0l-.993-.113v.004l-.002.008a4.535 4.535 0 00-.01.111A15.902 15.902 0 001 5.411a16.5 16.5 0 00.392 3.467c.6 2.634 2.121 5.781 5.835 7.067l.654-1.89c-2.786-.964-4.014-3.317-4.54-5.62A14.502 14.502 0 013 5.393a13.908 13.908 0 01.047-1.266v-.013l.001-.002c0 .001 0 .002-.994-.112z" />
    <path d="M13.056 4V3a1 1 0 01.993.886L13.056 4zM6.89 2.747a1 1 0 111.33-1.494L6.89 2.747zm.992 13.198a1 1 0 01-.655-1.89l.655 1.89zM13.056 4l-.001 1h-.019a3.684 3.684 0 01-.166-.005 11.494 11.494 0 01-1.943-.27C9.742 4.455 8.188 3.9 6.89 2.747l1.33-1.494c.952.846 2.148 1.293 3.152 1.522a9.494 9.494 0 001.581.222 3.923 3.923 0 00.105.003h-.002v1zm0 0l.993-.113v.004l.001.008.003.025.008.086a15.908 15.908 0 01.049 1.401 16.45 16.45 0 01-.392 3.467c-.6 2.634-2.122 5.781-5.835 7.067l-.655-1.89c2.787-.964 4.015-3.317 4.54-5.62.26-1.146.335-2.235.342-3.042a13.933 13.933 0 00-.041-1.206 3.437 3.437 0 00-.006-.06l-.001-.013v-.002c0 .001 0 .002.994-.112z" />
    <path d="M5.555 8.5l1.5 1.5 2.5-2.5" className={classes['shield-tick']} />
  </svg>
);

type CourierIconProps = {
  id: string;
  name: string;
};

export const CourierIcon = ({ id, name }: CourierIconProps) => (
  <img
    className={classes['shipping-icon']}
    alt={name}
    loading="lazy"
    decoding="async"
    width="42"
    height="25"
    src={`${process.env.NEXT_PUBLIC_ASSETS_BASE_URL}/static/icons/shipping/${id}.svg`}
  />
);
