import { useEffect, useState } from 'react';
import {
  AnubisClient,
  GiapeteClient,
  UserCategoryInfo,
  type UserAdInfo,
  type SearchData,
} from '@sbt-web/networking';

import { HADES_PATH } from '@shared/constants';

interface Info {
  message: string | null;
  numberOfAdsOnline: number | null;
  numberOfAdsPublished: number | null;
  categories: Array<UserCategoryInfo> | null;
}

interface LoadDataResponse {
  userAdInfo?: UserAdInfo;
  search?: SearchData;
}

const getPromiseSettledValue = <T>(
  promiseResponse: PromiseSettledResult<T | undefined>
): T | undefined =>
  promiseResponse.status === 'fulfilled' ? promiseResponse.value : undefined;

const loadData = async function (
  userId: string
): Promise<LoadDataResponse | undefined> {
  const GIAPETE_TIMEOUT = 30000;
  const anubisClient = new AnubisClient(HADES_PATH, 'web');
  const giapeteClient = new GiapeteClient(HADES_PATH, 'web', GIAPETE_TIMEOUT);

  const [userAdInfoResponse, searchResponse] = await Promise.allSettled([
    giapeteClient.getUserAdInfo(userId),
    anubisClient.search({ uid: userId, lim: 1 }),
  ]);

  const userAdInfoData = getPromiseSettledValue(userAdInfoResponse);
  const searchData = getPromiseSettledValue(searchResponse);

  return {
    userAdInfo: userAdInfoData?.userInfo,
    search: searchData?.search,
  };
};

const createMessage = function (date?: string): string | null {
  if (!date) {
    return null;
  }

  const epochTimestamp = Date.parse(date);

  if (Number.isNaN(epochTimestamp)) {
    console.error(`The date ${date} is an invalid date`);

    return null;
  }

  const messageDate = new Date(epochTimestamp);

  const monthAndYear = messageDate.toLocaleDateString('it', {
    year: 'numeric',
    month: 'long',
  });

  return `Pubblica da ${monthAndYear}`;
};

export function useInfo(userId: string): Info {
  const [infoComponent, setInfoComponent] = useState<Info>({
    categories: null,
    message: null,
    numberOfAdsOnline: null,
    numberOfAdsPublished: null,
  });

  useEffect(() => {
    let isRemoved = false;

    (async () => {
      const data = await loadData(userId);
      if (!isRemoved && data) {
        setInfoComponent({
          message: createMessage(data.userAdInfo?.firstAdInsertionDate),
          numberOfAdsPublished: data.userAdInfo?.totalApprovedAds || null,
          categories: data.userAdInfo?.userCategoriesInfo || null,
          numberOfAdsOnline: data.search?.total || null,
        });
      }
    })();

    return () => {
      isRemoved = true;
    };
  }, [userId]);

  return infoComponent;
}
