import {
  ADDETAIL_MSITE,
  AdSenseVariations,
  Channels,
  getChannel,
  ShortConfiguration,
} from '@client/components/Adv/AdSense';

enum IDs {
  Default = 'msite-bottom-addetail-tutti',
  Jobs = 'msite-bottom-addetail-jobs',
  Motor = 'msite-bottom-addetail-motor',
  Market = 'msite-bottom-addetail-market',
  RealEstate = 'msite-bottom-addetail-realestate',
}

const msiteBottomDefault: ShortConfiguration = {
  id: IDs.Default,
  pageOptions: {
    styleId: '3365576255',
    channel: getChannel([
      ...ADDETAIL_MSITE,
      Channels.Position.Bottom,
      'MSITE-BOTTOM-ADP-TUTTI-0-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 5 },
};

const msiteBottomJob: ShortConfiguration = {
  id: IDs.Jobs,
  pageOptions: {
    styleId: '4678658558',
    channel: getChannel([
      ...ADDETAIL_MSITE,
      Channels.Position.Bottom,
      Channels.Vertical.Job,
      Channels.Insertion.MsiteJobsDetailBottom,
      'MSITE-BOTTOM-ADP-JOBS-0-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 5 },
};

const msiteBottomMotor: ShortConfiguration = {
  id: IDs.Motor,
  pageOptions: {
    styleId: '7982234610',
    channel: getChannel([
      ...ADDETAIL_MSITE,
      Channels.Position.Bottom,
      Channels.Vertical.Motors,
      Channels.Insertion.MsiteMotoriDetailBottom,
      'MSITE-BOTTOM-ADP-MOTORS-0-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 5 },
};

const msiteBottomMarket: ShortConfiguration = {
  id: IDs.Market,
  pageOptions: {
    styleId: '3365576255',
    channel: getChannel([
      ...ADDETAIL_MSITE,
      Channels.Position.Bottom,
      Channels.Vertical.Market,
      Channels.Insertion.MsiteMarketDetailBottom,
      'MSITE-BOTTOM-ADP-MARKET-0-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 5 },
};

const msiteBottomRealEstate: ShortConfiguration = {
  id: IDs.RealEstate,
  pageOptions: {
    styleId: '7304823164',
    channel: getChannel([
      ...ADDETAIL_MSITE,
      Channels.Position.Bottom,
      Channels.Vertical.RealEstate,
      Channels.Insertion.MsiteRealEstateDetailBottom,
      'MSITE-BOTTOM-ADP-REALESTATE-0-LIGHT-NO',
    ]),
  },
  adBlockOptions: { number: 5 },
};

const msiteBottom: Array<ShortConfiguration> = [
  msiteBottomDefault,
  msiteBottomJob,
  msiteBottomMotor,
  msiteBottomMarket,
  msiteBottomRealEstate,
];

const variations: AdSenseVariations = {
  subito: IDs.Default,
  jobs: IDs.Jobs,
  motors: IDs.Motor,
  market: IDs.Market,
  realestate: IDs.RealEstate,
};

export { msiteBottom, IDs as MsiteBottomIDs, variations as MsiteBottom };
