import React from 'react';
import type { AdItem } from '@sbt-web/networking';

import { MakeYourPriceButton } from './MakeYourPriceButton';
import { ContactButton } from './ContactButton';
import { PhoneButton } from './PhoneButton';
import { getSearchValue } from '../../utils';

export type AdReplyButtonsProps = {
  isMobile: boolean;
  onContactButtonClick: () => void;
  adUrn: string;
  showPhone: boolean;
  item: AdItem;
  contactAsSecondaryButton?: boolean;
  makeAnOfferABTestVariant?: 'A' | 'B';
  onMakeYourPriceButtonClick: () => void;
};

export const AdReplyButtons = ({
  isMobile,
  onContactButtonClick,
  contactAsSecondaryButton,
  adUrn,
  showPhone,
  item,
  makeAnOfferABTestVariant = 'A',
  onMakeYourPriceButtonClick,
}: AdReplyButtonsProps) => {
  const searchValue = getSearchValue();

  return (
    <>
      {showPhone && (
        <PhoneButton
          adUrn={adUrn}
          isMobile={isMobile}
          search={searchValue}
          item={item}
          buttonDesign="outline"
          buttonSize="large"
          pulseEvent={{
            name: 'Mostra Numero',
            pageType: 'ad_detail',
          }}
        />
      )}
      {makeAnOfferABTestVariant === 'B' ? (
        <MakeYourPriceButton
          isSecondary={contactAsSecondaryButton}
          onClick={onMakeYourPriceButtonClick}
        />
      ) : (
        <ContactButton
          isSecondary={contactAsSecondaryButton}
          onClick={onContactButtonClick}
        />
      )}
    </>
  );
};
