import { AdTypes, CategoryId, FeatureURI } from '@sbt-web/networking';

const coreApartmentFeatures = [
  FeatureURI.BuildingCondition,
  FeatureURI.Size,
  FeatureURI.Floor,
  FeatureURI.Room,
  FeatureURI.Bathrooms,
  FeatureURI.Parking,
];

const coreVillaFeatures = [
  FeatureURI.BuildingCondition,
  FeatureURI.Size,
  FeatureURI.Room,
  FeatureURI.Bathrooms,
  FeatureURI.Parking,
];

const coreRoomFeatures = [
  FeatureURI.BuildingCondition,
  FeatureURI.RoomType,
  FeatureURI.Floor,
  FeatureURI.Bathrooms,
  FeatureURI.Gender,
  FeatureURI.Smoker,
  FeatureURI.Parking,
];

const coreOfficeFeatures = [
  FeatureURI.BuildingCondition,
  FeatureURI.OfficeType,
  FeatureURI.Size,
  FeatureURI.Floor,
  FeatureURI.Room,
  FeatureURI.Bathrooms,
  FeatureURI.Parking,
];

const coreVacationHouseFeatures = [
  FeatureURI.BuildingCondition,
  FeatureURI.Size,
  FeatureURI.Floor,
  FeatureURI.Room,
  FeatureURI.Bathrooms,
  FeatureURI.Parking,
];

const coreFeaturesByCategoryAndType = new Map<string, FeatureURI[]>([
  [`${CategoryId.Appartamenti}_${AdTypes.Sell}`, coreApartmentFeatures],
  [`${CategoryId.Appartamenti}_${AdTypes.Rent}`, coreApartmentFeatures],
  [`${CategoryId.Ville}_${AdTypes.Sell}`, coreVillaFeatures],
  [`${CategoryId.Ville}_${AdTypes.Rent}`, coreVillaFeatures],
  [`${CategoryId.CamerePostiLetto}_${AdTypes.Rent}`, coreRoomFeatures],
  [`${CategoryId.UfficiLocali}_${AdTypes.Sell}`, coreOfficeFeatures],
  [`${CategoryId.UfficiLocali}_${AdTypes.Rent}`, coreOfficeFeatures],
  [
    `${CategoryId.CaseVacanza}_${AdTypes.VacationRent}`,
    coreVacationHouseFeatures,
  ],
]);

const conditionLabel = 'Stato',
  roomTypeLabel = 'Tipologia',
  officeTypeLabel = 'Tipologia',
  sizeLabel = 'Superficie',
  floorLabel = 'Piano',
  roomLabel = 'Locali',
  bathroomsLabel = 'Bagni',
  genderLabel = 'Affittasi a',
  availableNowLabel = 'Disponibilità immediata',
  smokerLabel = 'Camere per fumatori',
  parkingLabel = 'Parcheggio';

const coreFeatureLabels = new Map<string, string>([
  [FeatureURI.BuildingCondition, conditionLabel],
  [FeatureURI.RoomType, roomTypeLabel],
  [FeatureURI.OfficeType, officeTypeLabel],
  [FeatureURI.Size, sizeLabel],
  [FeatureURI.Floor, floorLabel],
  [FeatureURI.Room, roomLabel],
  [FeatureURI.Bathrooms, bathroomsLabel],
  [FeatureURI.Gender, genderLabel],
  [FeatureURI.Smoker, smokerLabel],
  [FeatureURI.Parking, parkingLabel],
]);

const coreFeatureSortOrder = new Map<string, number>([
  [roomTypeLabel, 0],
  [officeTypeLabel, 1],
  [conditionLabel, 2],
  [sizeLabel, 3],
  [floorLabel, 4],
  [roomLabel, 5],
  [bathroomsLabel, 6],
  [genderLabel, 7],
  [smokerLabel, 8],
  [parkingLabel, 9],
]);

const balconyLabel = 'Balcone',
  gardenLabel = 'Giardino',
  elevatorLabel = 'Ascensore',
  furnishedLabel = 'Arredato',
  receptionLabel = 'Servizio portineria',
  lastFloorLabel = 'Ultimo piano',
  multiLevelLabel = 'Proprietà su più livelli',
  airConLabel = 'Aria condizionata';

const detailFeatureLabels = new Map<string, string>([
  [FeatureURI.Balcony, balconyLabel],
  [FeatureURI.Garden, gardenLabel],
  [FeatureURI.Elevator, elevatorLabel],
  [FeatureURI.Furnished, furnishedLabel],
  [FeatureURI.Reception, receptionLabel],
  [FeatureURI.LastFloor, lastFloorLabel],
  [FeatureURI.MultiLevel, multiLevelLabel],
  [FeatureURI.AvailableNow, availableNowLabel],
  [FeatureURI.AirConditioning, airConLabel],
]);

const detailFeatureSortOrder = new Map<string, number>([
  [balconyLabel, 0],
  [gardenLabel, 1],
  [elevatorLabel, 2],
  [furnishedLabel, 3],
  [receptionLabel, 4],
  [lastFloorLabel, 5],
  [multiLevelLabel, 6],
  [availableNowLabel, 7],
  [airConLabel, 8],
]);

const heatingLabel = 'Riscaldamento',
  energyClassLabel = 'Classe energetica';

const energyFeatureLabels = new Map<string, string>([
  [FeatureURI.Heating, heatingLabel],
  [FeatureURI.EnergyClass, energyClassLabel],
]);

const energyFeatureSortOrder: Map<string, number> = new Map([
  [heatingLabel, 0],
  [energyClassLabel, 1],
]);

export {
  coreFeatureLabels,
  coreFeatureSortOrder,
  detailFeatureLabels,
  detailFeatureSortOrder,
  energyFeatureLabels,
  energyFeatureSortOrder,
  coreFeaturesByCategoryAndType,
};
