import { BodyText, Button, Headline6 } from '@sbt-web/ui';
import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from './index.module.scss';
import { CategoryId } from '@sbt-web/networking';
import { getCategoryThemeById } from '@shared/helpers/Themes';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';

interface ConfirmMessageProps {
  isVisible: boolean;
  categoryId: CategoryId;
  advertiserName: string;
  onClick: () => void;
  recommendedSearch?: boolean;
}

export const ConfirmMessage = ({
  isVisible,
  categoryId,
  advertiserName,
  onClick,
  recommendedSearch = false,
}: ConfirmMessageProps) => {
  const imagePath = `/static/img/ad-reply/thankyou-${getCategoryThemeById(categoryId)}.svg`;
  const { optimizely } = React.useContext(OptimizelySubitoContext);

  useEffect(() => {
    if (categoryId === CategoryId.AbbigliamentoAccessori) {
      optimizely?.onReady().then(() => {
        optimizely.track('clothing_lead_web');
      });
    }
  }, [optimizely, categoryId]);

  return (
    <div
      className={classNames(styles.confirmMessage, {
        [styles.show]: isVisible,
      })}
    >
      <img
        className={styles.image}
        src={imagePath}
        alt=""
        width="240"
        height="180"
      />

      <Headline6 element="h2">Messaggio inviato</Headline6>

      <BodyText>
        Troverai la conversazione con {advertiserName} <br /> nella sezione
        messaggi o nella tua casella di posta
      </BodyText>

      <Button design="solid" onClick={onClick}>
        {recommendedSearch ? 'Vedi annunci Rivenditori' : 'Chiudi'}
      </Button>
    </div>
  );
};
