import React from 'react';
import { BodyText, Caption, Headline6, Overline, TextLink } from '@sbt-web/ui';

import type {
  CostWithDiscount,
  ShippingCosts,
  SubitoShipping,
} from 'server/detail-requests/ShippingCost';

import ownClasses from './TuttoSubitoInfo.module.scss';
import { DiscountedCost } from './DiscountedCost';
import {
  CourierIcon,
  DeliveryPointShippingIcon,
  HomeShippingIcon,
  PaymentIcon,
} from './shippingIcons';

type HomeShippingRowProps = {
  cost: CostWithDiscount;
};

const HomeShippingRow = ({ cost }: HomeShippingRowProps) => (
  <div className={ownClasses['shipping-cost-wrapper']}>
    <div className={ownClasses['shipping-destination-icon-wrapper']}>
      <HomeShippingIcon />
    </div>

    <div className={ownClasses['shipping-cost']}>
      <BodyText weight="semibold">Consegna a domicilio</BodyText>
      <DiscountedCost costWithDiscount={cost} />
    </div>
    <Caption classes={[ownClasses['shipping-text']]} size="small" weight="book">
      Il pacco verrà recapitato all&apos;indirizzo da te scelto
    </Caption>
  </div>
);

type TuttoSubitoShippingProps = {
  costs: SubitoShipping;
};

const TuttoSubitoShipping = ({ costs }: TuttoSubitoShippingProps) => (
  <>
    {costs.minCostToDeliveryPoint && (
      <>
        <div className={ownClasses['shipping-cost-wrapper']}>
          <div className={ownClasses['shipping-destination-icon-wrapper']}>
            <DeliveryPointShippingIcon />
          </div>

          <div className={ownClasses['shipping-cost']}>
            <div className={ownClasses['cost-with-icon']}>
              <BodyText weight="semibold">Punto di ritiro</BodyText>
              <img
                id={ownClasses['leaf-icon']}
                src="/static/img/tuttosubito/leaf.svg"
                width="16"
                height="16"
                alt="Una scelta sostenibile"
                loading="lazy"
                decoding="async"
              />
            </div>
            <DiscountedCost costWithDiscount={costs.minCostToDeliveryPoint} />
          </div>
          <Caption
            classes={[ownClasses['shipping-text']]}
            size="small"
            weight="book"
          >
            Ritiro del pacco presso un negozio o un locker
          </Caption>
        </div>
        <Caption
          size="small"
          weight="book"
          classes={[ownClasses['shipping-option-separator']]}
        >
          Oppure
        </Caption>
      </>
    )}
    <HomeShippingRow cost={costs.costToHome} />
  </>
);

type ShippingMethodsProps = {
  costs: ShippingCosts;
};

export const ShippingMethods = ({ costs }: ShippingMethodsProps) => (
  <>
    <Headline6 element="h2" classes={[ownClasses.title]}>
      Modalità di consegna
    </Headline6>

    <BodyText size="small" classes={[ownClasses.subtitle]}>
      {costs.type === 'tuttosubito' && costs.minCostToDeliveryPoint
        ? 'In fase di acquisto potrai scegliere tra queste opzioni'
        : "In fase di acquisto potrai inserire l'indirizzo"}
    </BodyText>

    <div className={ownClasses['shipping-options']}>
      {costs.type === 'self' ? (
        <HomeShippingRow cost={{ partial: false, cost: costs.cost }} />
      ) : (
        <TuttoSubitoShipping costs={costs} />
      )}
    </div>

    {costs.type === 'self' ? (
      <Overline classes={[ownClasses['shipping-type']]}>
        Spedizione gestita dal venditore
      </Overline>
    ) : (
      <>
        <Overline classes={[ownClasses['shipping-type']]}>
          Spedizione gestita da Subito
        </Overline>

        <div className={ownClasses['shipping-icons-container']}>
          {costs.couriers.map((carrierInfo) => (
            <CourierIcon
              key={carrierInfo.id}
              id={carrierInfo.id}
              name={carrierInfo.name}
            />
          ))}
        </div>
      </>
    )}
  </>
);

export const PaymentMethods = () => (
  <>
    <Headline6 element="h2" classes={[ownClasses.title]}>
      Modalità di pagamento
    </Headline6>

    <BodyText
      weight="semibold"
      classes={[ownClasses.subtitle, ownClasses['subtitle-with-icon']]}
    >
      <PaymentIcon />
      Pagamento su Subito
    </BodyText>

    <BodyText size="small" classes={[ownClasses['safe-payment-message']]}>
      Paga in totale sicurezza con <b>TuttoSubito</b> e beneficia della
      Protezione Acquisti. I tuoi dati di pagamento non vengono mai condivisi
      con il venditore.
    </BodyText>

    <TextLink
      size="sm"
      href="https://info.subito.it/affariadistanza/#compra"
      target="_blank"
      rel="noopener"
      classes={[ownClasses['tutto-subito-info']]}
    >
      Scopri di più sul nostro servizio TuttoSubito
    </TextLink>
  </>
);
