import React, { type PropsWithChildren } from 'react';
import {
  AdsGAMPlacement,
  DesktopAdDetailGAMIds,
} from '@client/components/Adv/GPT';
import {
  AdvDetailContext,
  type AdvDetailContextType,
} from '@client/components/Adv/AdvAdDetailContext';
import styles from './index.module.scss';

const SkeletonWithAdv = ({
  children,
  contextData,
}: { contextData: AdvDetailContextType } & PropsWithChildren) => {
  return (
    <AdvDetailContext.Provider value={contextData}>
      <div className={styles['skeleton']}>
        <aside className={styles['left-aside']}>
          <div className={styles['aside-wrapper']}>
            <div className={styles['aside-skyscraper']}>
              <div id={DesktopAdDetailGAMIds.LEFT_SKYSCRAPER} />
            </div>
          </div>
        </aside>

        <div className={styles['main']}>
          <AdsGAMPlacement
            classes={[styles['billboard']]}
            id={DesktopAdDetailGAMIds.BILLBOARD}
            fixedHeight={250}
            displayPlaceholder={true}
          />
          {children}
        </div>

        <aside className={styles['right-aside']}>
          <div className={styles['aside-wrapper']}>
            <div className={styles['aside-skyscraper']}>
              <div id={DesktopAdDetailGAMIds.RIGHT_SKYSCRAPER} />
            </div>
          </div>
        </aside>
      </div>
    </AdvDetailContext.Provider>
  );
};

export { SkeletonWithAdv };
